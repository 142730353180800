var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex": "1",
      "flex-dir": "column",
      "overflow": "auto",
      "pb": "30px"
    }
  }, [_c('c-box', {
    attrs: {
      "px": "40px",
      "py": "40px"
    }
  }, [_c('c-heading', {
    attrs: {
      "color": "black.900",
      "font-size": "24px",
      "font-weight": "700",
      "margin-bottom": "40px",
      "text-transform": "uppercase"
    }
  }, [_vm._v(" Asupan Gizi ")]), _c('EditNutritionForm', {
    on: {
      "submit": _vm.submit
    },
    model: {
      value: _vm.nutrition,
      callback: function callback($$v) {
        _vm.nutrition = $$v;
      },
      expression: "nutrition"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }