<template>
  <c-flex
    flex="1"
    flex-dir="column"
    overflow="auto"
    pb="30px"
  >
    <c-box
      px="40px"
      py="40px"
    >
      <c-heading
        color="black.900"
        font-size="24px"
        font-weight="700"
        margin-bottom="40px"
        text-transform="uppercase"
      >
        Asupan Gizi
      </c-heading>
      <EditNutritionForm
        v-model="nutrition"
        @submit="submit"
      />
    </c-box>
  </c-flex>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import clientIdMixin from '@/utils/clientId-mixins'
import EditNutritionForm from './forms/edit-nutrition-form.vue'

export default {
  name: 'NutritionEdit',
  components: { EditNutritionForm },
  mixins: [clientIdMixin],
  data() {
    return {
      nutrition: {},
      programId: null,
    }
  },
  computed: {
    ...mapGetters({
      asupanGizi: 'mealPlan/asupanGizi',
    }),
  },
  watch: {
    asupanGizi: {
      immediate: true,
      handler(val, old) {
        if (val !== old) {
          this.nutrition = { ...val }
        }
      },
    },
  },
  async mounted() {
    this.getAsupanGizi(this.clientId)
    let program = await this.$store.dispatch('mealPlan/getLatestProgramForNutri', this.clientId)
    this.programId = program?.id
  },
  methods: {
    ...mapActions({
      getAsupanGizi: 'mealPlan/getAsupanGizi',
      updateAsupanGizi: 'mealPlan/updateAsupanGizi',
      postAsupanGizi: 'mealPlan/postAsupanGizi',
    }),
    async submit() {
      let data = {
        ...this.nutrition,
        clientId: this.clientId,
        currentcarbohydrateIntake: this.nutrition.currentCarbohydrateIntake,
        programId: this.programId,
      }

      let promise
      if (this.nutrition?.id != null) {
        promise = this.updateAsupanGizi({ asupanGizi: data, clientId: this.clientId })
      } else {
        promise = this.postAsupanGizi({ asupanGizi: data, clientId: this.clientId })
      }
      await promise.then(() => this.$router.push({
        name: 'nutri.profile-gizi',
        params: { clientId: this.clientId },
      }))
    },
  },
}
</script>
