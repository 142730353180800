<template>
  <c-box
    w="100%"
    max-width="590px"
  >
    <EnergiForm
      v-if="profileGizi"
      :value="value"
      :aktivitas-fisik="profileGizi.physicalActivity"
      :defisit-kalori="profileGizi.calorieDeficit"
      :kebutuhan-energi="profileGizi.basalEnergyNeeds"
      :tambahan-kalori="profileGizi.extraMaternalCalories"
      @input="$emit('input', {...value, ...$event})"
    />
    <ProteinForm
      v-if="profileGizi"
      :value="value"
      :weight="profileGizi.weight"
      :kebutuhan-asupan-energi="value.energyIntakeNeeds"
      @input="$emit('input', {...value, ...$event})"
    />
    <LemakForm
      v-if="profileGizi"
      :value="value"
      :kebutuhan-asupan-energi="value.energyIntakeNeeds"
      @input="$emit('input', {...value, ...$event})"
    />
    <KarbohidratForm
      v-if="profileGizi"
      :value="value"
      :kebutuhan-asupan-energi="+value.energyIntakeNeeds"
      :kebutuhan-lemak="+value.fatNeedsConstant"
      :kebutuhan-protein="+value.proteinNeedsConstant"
      @input="$emit('input', {...value, ...$event})"
    />
    <SeratForm
      v-if="profileGizi"
      :value="value"
      @input="$emit('input', {...value, ...$event})"
    />

    <c-form-control
      margin-bottom="30px"
      display="flex"
    >
      <c-button
        background-color="superLightGray.900"
        px="26px"
        font-size="18px"
        font-weight="500"
        py="26px"
        border-radius="58px"
        width="100%"
        color="darkGray.900"
        margin-right="20px"
        margin-bottom="20px"
        as="router-link"
        :to="{ name: 'nutri.profile-gizi' }"
      >
        Batal
      </c-button>
      <c-button
        background-color="primary.400"
        px="26px"
        font-size="18px"
        font-weight="500"
        py="26px"
        border-radius="58px"
        width="100%"
        color="white"
        @click="$emit('submit')"
      >
        Simpan
      </c-button>
    </c-form-control>
  </c-box>
</template>

<script>
import EnergiForm from '@/views/meal-plan/forms/asupan-gizi-form/energi-form'
import ProteinForm from '@/views/meal-plan/forms/asupan-gizi-form/protein-form'
import LemakForm from '@/views/meal-plan/forms/asupan-gizi-form/lemak-form'
import KarbohidratForm from '@/views/meal-plan/forms/asupan-gizi-form/karbohidrat-form'
import SeratForm from '@/views/meal-plan/forms/asupan-gizi-form/serat-form'
import { mapGetters } from 'vuex'
import clientIdMixins from '@/utils/clientId-mixins'

export default {
  name: 'EditNutritionForm',
  components: {
    KarbohidratForm,
    LemakForm,
    ProteinForm,
    EnergiForm,
    SeratForm,
  },
  mixins: [clientIdMixins],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      profileGizi: 'mealPlan/profileGizi',
    }),
  },
  async mounted() {
    // get profile gizi client
    await this.$store.dispatch('mealPlan/getProfileGizi', this.clientId)
  },
}
</script>
