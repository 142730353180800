var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "590px"
    }
  }, [_vm.profileGizi ? _c('EnergiForm', {
    attrs: {
      "value": _vm.value,
      "aktivitas-fisik": _vm.profileGizi.physicalActivity,
      "defisit-kalori": _vm.profileGizi.calorieDeficit,
      "kebutuhan-energi": _vm.profileGizi.basalEnergyNeeds,
      "tambahan-kalori": _vm.profileGizi.extraMaternalCalories
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', Object.assign({}, _vm.value, $event));
      }
    }
  }) : _vm._e(), _vm.profileGizi ? _c('ProteinForm', {
    attrs: {
      "value": _vm.value,
      "weight": _vm.profileGizi.weight,
      "kebutuhan-asupan-energi": _vm.value.energyIntakeNeeds
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', Object.assign({}, _vm.value, $event));
      }
    }
  }) : _vm._e(), _vm.profileGizi ? _c('LemakForm', {
    attrs: {
      "value": _vm.value,
      "kebutuhan-asupan-energi": _vm.value.energyIntakeNeeds
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', Object.assign({}, _vm.value, $event));
      }
    }
  }) : _vm._e(), _vm.profileGizi ? _c('KarbohidratForm', {
    attrs: {
      "value": _vm.value,
      "kebutuhan-asupan-energi": +_vm.value.energyIntakeNeeds,
      "kebutuhan-lemak": +_vm.value.fatNeedsConstant,
      "kebutuhan-protein": +_vm.value.proteinNeedsConstant
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', Object.assign({}, _vm.value, $event));
      }
    }
  }) : _vm._e(), _vm.profileGizi ? _c('SeratForm', {
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', Object.assign({}, _vm.value, $event));
      }
    }
  }) : _vm._e(), _c('c-form-control', {
    attrs: {
      "margin-bottom": "30px",
      "display": "flex"
    }
  }, [_c('c-button', {
    attrs: {
      "background-color": "superLightGray.900",
      "px": "26px",
      "font-size": "18px",
      "font-weight": "500",
      "py": "26px",
      "border-radius": "58px",
      "width": "100%",
      "color": "darkGray.900",
      "margin-right": "20px",
      "margin-bottom": "20px",
      "as": "router-link",
      "to": {
        name: 'nutri.profile-gizi'
      }
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "background-color": "primary.400",
      "px": "26px",
      "font-size": "18px",
      "font-weight": "500",
      "py": "26px",
      "border-radius": "58px",
      "width": "100%",
      "color": "white"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('submit');
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }